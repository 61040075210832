import React from 'react'
import styled from 'styled-components'

const SectionGroup = styled.div`
  /* background: url(${props => props.image});
  background-size: cover; */
  background-color: #293A7E;
  color: white;
  display: grid;
  position:relative;
  padding-top:50px;
  padding-bottom:50px;
`

const SectionTextGroup = styled.div``

const SectionTtitleGroup = styled.div`
  display: grid;
  margin: 0 40px;
  grid-gap: 20px;
  background-color: (${props => props.image});
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }
`
const SectionTitle = styled.h3`
  color: white;
  margin: 0;
  font-size: 60px;
  line-height: 1.2;

  @media (max-width: 720px) {
    font-size: 40px;
  }
`
const SectionText = styled.p`
  color: white;
`

const SectionImageGroup = styled.div`
  margin: 0 3vw;
  overflow: hidden;
  display: grid;
  justify-content: center;
  a {
    min-width: 0;
    overflow: hidden;
    max-height: 800px;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    display: grid;
    max-height: 500px;
    object-fit: contain;
  }

  @media (max-width: 720px) {
    margin: 6vh 3vw;
  }
`
const Section = props => (
  <SectionGroup>
    <SectionTtitleGroup>
      <SectionTextGroup>
        <SectionTitle> {props.title} </SectionTitle>
        <SectionText>{props.text}</SectionText>
        <SectionText>{props.textmore}</SectionText>
      </SectionTextGroup>
      <SectionImageGroup>
        <a href={props.url}>
          {' '}
          <img src={props.image} img />{' '}
        </a>
      </SectionImageGroup>
    </SectionTtitleGroup>
  </SectionGroup>
)

export default Section
