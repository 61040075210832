import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Selection from '../components/section.1.js'

import Layout from '../components/Layout'
import SelectionNext from '../components/moresectionwhite.js'

const LocationPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <div className="Hero">
      <div className="HeroGroup">
        <h1>
          <FormattedMessage id="ourlocation" />
        </h1>
      </div>
    </div>
    <Selection
      title={<FormattedMessage id="ourlocationtitle" />}
      text={<FormattedMessage id="ourlocationtext" />}
      image={require('../Images/window.png')}
      textmore={<FormattedMessage id="ourlocationtextmore" />}
    />
    <Selection
      title={<FormattedMessage id="howtofindustitle" />}
      text={<FormattedMessage id="howtofindustext" />}
      image={require('../Images/hotelkima.jpg')}
    />
    <Selection
      title={<FormattedMessage id="whatisnearustitle" />}
      image={require('../Images/distances.jpg')}
      text={<FormattedMessage id="whatisnearustext" />}
    />

    <SelectionNext
      titlefirstoption={<FormattedMessage id="OurRooms" />}
      textfirstoption={<FormattedMessage id="OurRoomsIntro" />}
      buttonfirstoption={<FormattedMessage id="Checktherooms" />}
      link1="/rooms"
      titlesecondoption={<FormattedMessage id="Services" />}
      textsecondoption={<FormattedMessage id="OurServicesIntro" />}
      buttonsecondoption={<FormattedMessage id="Checktheservices" />}
      link2="/services"
    />
  </Layout>
)

LocationPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default LocationPage
